import { navigate, PageProps } from "gatsby"
import React from "react"
import Button from "../../../components/Button"
import ActeNecesare, {
  ActNecesar,
} from "../../../components/CereriNew/ActeNecesare"
import FormeErrorLine from "../../../components/CereriNew/FormErrorLine"
import NewFormFileInput from "../../../components/CereriNew/FormFileInput"
import NewTabs, { I_NewTabItem } from "../../../components/CereriNew/NewTabs"
import { casnicSvg, comercialSvg } from "../../../components/Dash/svgs"
import Footer from "../../../components/Footer"
import FormInput from "../../../components/FormInput"
import { useFileInput } from "../../../components/Hooks/useFileInput"
import useScrollRestorationToTop from "../../../components/Hooks/useScrollRestorationToTop"
import { InfoMsgType } from "../../../components/InfoMsg"
import SiteMenu from "../../../components/siteMenu"
import {
  ALFA_NUMERIC_ERROR,
  ALFA_NUMERIC_REGEX,
  AMOUNT_ERROR,
  AMOUNT_REGEX,
  CNP_ERROR,
  CNP_REGEX,
  EMAIL_ERROR,
  EMAIL_REGEX,
  PHONENUMBER_ERROR,
  PHONENUMBER_REGEX,
} from "../../../utils/regex"
import { getUserInfo } from "../../../utils/services/keepAuth"

import { Helmet } from "react-helmet"
import { calcFilesSize } from "../../../components/Cereri/MultiFileUploader"
import NewCheckBox from "../../../components/CereriNew/CheckBox"
import CheckBoxAgreeToTerms from "../../../components/CereriNew/CheckBoxAgreeToTerms"
import PopupLayout from "../../../components/Popups/PopupLayout"
import { postCerereAvizAmplasament } from "../../../utils/api/cerereAvizAmplasament"

type DataCerereAvizAmplasament = {
  isCasnic: boolean
  denumire: string
  cuicnp: string
  adresa: string
  localitate: string
  judet: string
  numeReprezentant?: string
  email: string
  telefon: string
  locConsum: {
    isSame: boolean
    adresa: string
    localitate: string
    judet: string
  }
  motivEliberare: string
  lungimeConducta: number | null
  agreedToTerms: boolean
  agreedToContact: boolean
  agreedToSend: boolean
}
const initialFormData: DataCerereAvizAmplasament = {
  isCasnic: true,
  denumire: "",
  cuicnp: "",
  adresa: "",
  localitate: "",
  judet: "",
  email: "",
  telefon: "",
  locConsum: {
    isSame: true,
    adresa: "",
    localitate: "",
    judet: "",
  },
  motivEliberare: "",
  lungimeConducta: null,
  agreedToTerms: false,
  agreedToContact: false,
  agreedToSend: false,
}

const listaActeNecesare: ActNecesar[][] = [
  [
    {
      title: "Memoriu Tehnic",
    },
    {
      title: "Copie certificat de urbanism - 1 exemplar",
    },
    {
      title:
        "Plan de încadrare în zonă cu lucrările ce se executa,scara 1:5000 sau 1:1000",
    },
    { title: "Plan de situații avizat de OJCGCscara 1:500 sau 1:1000" },
    { title: "Carte de indentitate titular aviz amplasament" },
  ],
  [
    {
      title: "Memoriu Tehnic",
    },
    {
      title: "Certificat de urbanism - 1 exemplar",
    },
    {
      title:
        "Plan de încadrare în zonă cu lucrările ce se executa,scara 1:5000 sau 1:1000",
    },
    { title: "Plan de situații avizat de OJCGCscara 1:500 sau 1:1000" },
    { title: "Certificat Inregistrare Fiscală" },
  ],
]
const cereriButtonText = [
  "Începe completarea cererii",
  "Pasul următor",
  "Trimite cererea",
]
const tipClientTabItems: I_NewTabItem[] = [
  {
    id: 1,
    title: "Casnic",
    icon: casnicSvg,
    iconClassName: "casnic",
  },
  {
    id: 2,
    title: "Comercial",
    icon: comercialSvg,
    iconClassName: "comercial",
  },
]
const locConsumTabItems: I_NewTabItem[] = [
  {
    id: 1,
    title: "Da",
  },
  {
    id: 2,
    title: "Nu",
  },
]

function getFormErrorMessageForStep(step: number) {
  switch (step) {
    case 1:
      return "Te rugăm să anexezi documentele necesare"
    case 2:
      return "Te rugăm să completezi toate câmpurile"
    case 3:
      return "Acceptă termenii și condițiile pentru a putea trimite cererea"
    default:
      return ""
  }
}

function isButtonEnabled(
  step: number,
  formData: DataCerereAvizAmplasament,
  files: File[]
) {
  if (step === 1) {
    return files.length > 0 && calcFilesSize(files) < 14.99
  }
  if (step === 2) {
    return (
      ALFA_NUMERIC_REGEX(formData.denumire) &&
      (formData.isCasnic
        ? CNP_REGEX(formData.cuicnp)
        : ALFA_NUMERIC_REGEX(formData.cuicnp)) &&
      ALFA_NUMERIC_REGEX(formData.adresa) &&
      ALFA_NUMERIC_REGEX(formData.localitate) &&
      ALFA_NUMERIC_REGEX(formData.judet) &&
      EMAIL_REGEX(formData.email) &&
      PHONENUMBER_REGEX(formData.telefon) &&
      (!formData.locConsum.isSame
        ? ALFA_NUMERIC_REGEX(formData.locConsum.adresa) &&
          ALFA_NUMERIC_REGEX(formData.locConsum.localitate) &&
          ALFA_NUMERIC_REGEX(formData.locConsum.judet)
        : true) &&
      ALFA_NUMERIC_REGEX(formData.motivEliberare) &&
      AMOUNT_REGEX(formData.lungimeConducta?.toString() ?? "") &&
      formData.agreedToTerms &&
      formData.agreedToContact &&
      formData.agreedToSend
    )
  }

  return false
}

export default function CerereAvizAmplasament({ location, params }: PageProps) {
  const [infoMsg, setInfoMsg] = React.useState<InfoMsgType>({
    isLoading: false,
    // status: "error",
  })
  const [step, setStep] = React.useState<number>(1)
  const [formData, setFormData] =
    React.useState<DataCerereAvizAmplasament>(initialFormData)
  const [nrHover, setNrHover] = React.useState(0)

  const timeOutRefs = React.useRef<NodeJS.Timeout[]>([])

  const fileInputRef = React.useRef<HTMLInputElement | null>(null)
  const { files, removeFile, resetFiles, changeFiles, uploadFiles } =
    useFileInput(fileInputRef)

  React.useEffect(() => {
    // Page unmount cleanup function
    return () => {
      timeOutRefs.current.forEach(clearTimeout)
    }
  }, [])

  React.useEffect(() => {
    const step = parseInt(params["step"] ?? "-1")
    if (!isNaN(step) && step > 0 && step < 5) {
      let maxStep = 1
      for (let i = maxStep; i < step; i++) {
        if (isButtonEnabled(i, formData, files)) {
          maxStep = i
        }
      }
      // console.log("Max step: ", maxStep, step)
      if (maxStep + 1 < step) {
        navigate(`../${maxStep + 1}`)
      } else setStep(Math.min(maxStep + 1, step))
      // navigate(`../${maxStep + 1}`)
    } else navigate("../1")
  }, [params])

  React.useEffect(() => {
    setNrHover(0)
  }, [step])

  useScrollRestorationToTop("body", location.pathname)

  const handleFormDataChange = (value: string, name: string) => {
    setFormData(oldFormData => ({
      ...oldFormData,
      [name]: value,
    }))
  }

  const handleSubmit = async () => {
    const root = document.getElementById("cerereAvizAmplasamentPage")
    if (root != null)
      root.scrollTo({
        top: 0,
        left: 0,
        behavior: "instant",
      })
    setInfoMsg({
      isLoading: true,
    })

    // console.log("Handle form submit")
    try {
      const data = new FormData()

      for (var i in files) data.append("file", files[i])

      if (getUserInfo() != null) data.append("accountId", getUserInfo()!._id)

      data.append("isCasnic", formData.isCasnic + "")
      data.append("denumire", formData.denumire)
      data.append("cuicnp", formData.cuicnp)
      data.append("adresa", formData.adresa)
      data.append("localitate", formData.localitate)
      data.append("judet", formData.judet)
      data.append("email", formData.email)
      data.append("telefon", formData.telefon)
      if (!formData.locConsum.isSame) {
        data.append("lcadresa", formData.locConsum.adresa)
        data.append("lclocalitate", formData.locConsum.localitate)
        data.append("lcjudet", formData.locConsum.judet)
      }
      if (formData.numeReprezentant != null)
        data.append("numeReprezentantLegal", formData.numeReprezentant)

      data.append("motivEliberare", formData.motivEliberare)
      data.append("lungimeConducta", formData.lungimeConducta + "")

      data.append("acordDate", formData.agreedToTerms + "")
      data.append("acordContactPlata", formData.agreedToContact + "")
      // data.append("acordContactCompletari", formData.acordContactCompletari)
      data.append("acordVerificare", formData.agreedToSend + "")
      const apiResponse = await postCerereAvizAmplasament(data)
      if (apiResponse.type) {
        setInfoMsg({
          isLoading: true,
          status: "ok",
          msg: "Veți primi un email de confirmarea.  Te redirecționăm către pagina principală",
        })
        timeOutRefs.current.push(
          setTimeout(() => {
            navigate("/")
          }, 5000)
        )
        // setFormData(initialFormData)
      } else if (apiResponse.filesToLarge) {
        setInfoMsg({
          isLoading: true,
          status: "error",
          msg: "A apărut o eroare la transmiterea cererii. Fișierele încărcate depășesc limita de 20MB. Scoateți fișierele care au o dimensiunea mare. Vă vom solicita restul documentelor prin email. Te redirecționăm către pasul încarcare documente",
        })
        timeOutRefs.current.push(
          setTimeout(() => {
            setStep(2)
            setInfoMsg({ isLoading: false })
          }, 5000)
        )
      } else {
        setInfoMsg({
          isLoading: true,
          status: "error",
          msg: "Vă rugăm reîncercați. Acest pop-up va dispărea în câteva momente!",
        })
        timeOutRefs.current.push(
          setTimeout(() => {
            setInfoMsg({ isLoading: false })
          }, 3000)
        )
      }
    } catch (error) {
      console.warn(error)
      setInfoMsg({
        isLoading: true,
        status: "error",
        msg: "Vă rugăm reîncercați.Acest pop-up va dispărea în câteva momente!",
      })
      timeOutRefs.current.push(
        setTimeout(() => {
          setInfoMsg({ isLoading: false })
        }, 3000)
      )
    }
  }

  const handleLocConsumDataChange = (value: string, name: string) => {
    setFormData(oldData => ({
      ...oldData,
      locConsum: {
        ...oldData.locConsum,
        [name as keyof DataCerereAvizAmplasament["locConsum"]]: value,
      },
    }))
  }

  // console.log(formData)
  // console.log(files)

  console.log(infoMsg)

  return (
    <div id="cerereAvizAmplasamentPage" className="cererePage">
      <Helmet>
        <title>Cerere Aviz Amplasament - Gazmir.ro</title>
        <meta
          name="description"
          content="Depune o Cerere pentru obtinerea unui Aviz Amplasament pe Gazmir.ro. Vezi etapele si documentele necesare obtinerii avizului de amplasament."
        />
        <meta
          name="keywords"
          content="Aviz Amplasament Gazmir,Aviz Amplasament miroslava gazmir,Aviz Amplasament panciu gazmir,Aviz Amplasament odobesti gazmir,Aviz Amplasament chirnogi gazmir,Aviz Gazmir,Gazmir aviz,aviz amplasament gaz miroslava,aviz amplasament gaz panciu,aviz amplasament gaz odobesti,aviz amplasament gaz chirnogi,cerere aviz gazmir,aviz gazmir cerere,cerere aviz amplasament gazmir,Gazmir cerere aviz"
        />
      </Helmet>
      <SiteMenu location={location} showPaymentRedirectLoading={false} />

      <form id="formAvizAmplasament" className="newCereriContainer">
        <h1 className="title">Cerere aviz de amplasament</h1>
        {step === 1 && (
          <div className="cerereStep1 cerereStep2">
            <NewTabs
              containerClassName="tipClientContainer"
              title="Tip client"
              subTitle="Ești client casnic sau comercial?"
              items={tipClientTabItems}
              idTabSelected={formData.isCasnic ? 1 : 2}
              setIdTabSelected={(id: number) =>
                setFormData(oldData => ({ ...oldData, isCasnic: id === 1 }))
              }
            />
            <div className="stepTitle marginTop24">Acte necesare</div>
            <div className="stepSubTitle">
              Te rugăm să te asiguri că toate datele sunt vizibile și lizibile
            </div>
            <ActeNecesare
              items={listaActeNecesare[formData.isCasnic ? 0 : 1]}
              withoutText
            />

            <NewFormFileInput
              files={files}
              changeFiles={changeFiles}
              removeFile={removeFile}
              fileInputRef={fileInputRef}
              id="fileInputavizAmplasament"
              buttonLabel="Anexează documente"
            />
          </div>
        )}
        {step === 2 && (
          <div className="cerereStep3">
            <div className="formDate formDateIdentificare ncsShadow">
              <div className="formSectionTitle">
                {formData.isCasnic
                  ? "Date personale titular (din C.I.)"
                  : "Date de indetificare societate"}
              </div>
              <FormInput
                type="text"
                key={formData.isCasnic ? "CD" : "PD"}
                containerClassName="ncsInputContainer"
                label={
                  formData.isCasnic ? "Nume și prenume" : "Denumire societate"
                }
                name="denumire"
                onChange={handleFormDataChange}
                value={formData.denumire}
                errorTest={ALFA_NUMERIC_REGEX}
                errorMessage={ALFA_NUMERIC_ERROR}
                showWithoutTouched={nrHover > 1}
              />
              <FormInput
                type="text"
                key={formData.isCasnic ? "CC" : "PC"}
                containerClassName="ncsInputContainer"
                label={
                  formData.isCasnic
                    ? "CNP - Cod Numeric Personal"
                    : "Certificat unic de înregistrare (C.U.I.)"
                }
                name="cuicnp"
                onChange={handleFormDataChange}
                value={formData.cuicnp}
                errorTest={formData.isCasnic ? CNP_REGEX : ALFA_NUMERIC_REGEX}
                errorMessage={
                  formData.isCasnic ? CNP_ERROR : ALFA_NUMERIC_ERROR
                }
                showWithoutTouched={nrHover > 1}
              />

              <FormInput
                type="text"
                key={formData.isCasnic ? "CA" : "PA"}
                containerClassName="ncsInputContainer"
                // containerStyle={cereriStyles.inputContainer}
                // style={cereriStyles.input}
                // errorStyle={cereriStyles.inputError}
                label={
                  formData.isCasnic ? "Adresă Domiciliu" : "Adresă sediu social"
                }
                name="adresa"
                onChange={handleFormDataChange}
                value={formData.adresa}
                errorTest={ALFA_NUMERIC_REGEX}
                errorMessage={ALFA_NUMERIC_ERROR}
                showWithoutTouched={nrHover > 1}
              />
              <FormInput
                type="text"
                key={formData.isCasnic ? "CL" : "PL"}
                containerClassName="ncsInputContainer"
                // containerStyle={cereriStyles.inputContainer}
                // style={cereriStyles.input}
                // errorStyle={cereriStyles.inputError}
                label="Localitate"
                name="localitate"
                onChange={handleFormDataChange}
                value={formData.localitate}
                errorTest={ALFA_NUMERIC_REGEX}
                errorMessage={ALFA_NUMERIC_ERROR}
                showWithoutTouched={nrHover > 1}
              />
              <FormInput
                type="text"
                key={formData.isCasnic ? "CJ" : "PJ"}
                containerClassName="ncsInputContainer"
                // containerStyle={cereriStyles.inputContainer}
                // style={cereriStyles.input}
                // errorStyle={cereriStyles.inputError}
                label="Județ"
                name="judet"
                onChange={handleFormDataChange}
                value={formData.judet}
                errorTest={ALFA_NUMERIC_REGEX}
                errorMessage={ALFA_NUMERIC_ERROR}
                showWithoutTouched={nrHover > 1}
              />
              {!formData.isCasnic && (
                <FormInput
                  type="text"
                  key="PNR"
                  containerClassName="ncsInputContainer"
                  // containerStyle={cereriStyles.inputContainer}
                  // style={cereriStyles.input}
                  // errorStyle={cereriStyles.inputError}
                  label="Numele reprezentantului legal"
                  name="numeReprezentant"
                  onChange={handleFormDataChange}
                  value={formData.numeReprezentant}
                  errorTest={ALFA_NUMERIC_REGEX}
                  errorMessage={ALFA_NUMERIC_ERROR}
                  showWithoutTouched={nrHover > 1}
                />
              )}
              <FormInput
                type="text"
                key={formData.isCasnic ? "CE" : "PE"}
                containerClassName="ncsInputContainer"
                // containerStyle={cereriStyles.inputContainer}
                // style={cereriStyles.input}
                // errorStyle={cereriStyles.inputError}
                label="Email"
                name="email"
                onChange={handleFormDataChange}
                value={formData.email}
                errorTest={EMAIL_REGEX}
                errorMessage={EMAIL_ERROR}
                showWithoutTouched={nrHover > 1}
              />
              <FormInput
                type="text"
                key={formData.isCasnic ? "CT" : "PT"}
                containerClassName="ncsInputContainer"
                // containerStyle={cereriStyles.inputContainer}
                // style={cereriStyles.input}
                // errorStyle={cereriStyles.inputError}
                label="Număr de telefon"
                name="telefon"
                onChange={handleFormDataChange}
                value={formData.telefon}
                errorTest={PHONENUMBER_REGEX}
                errorMessage={PHONENUMBER_ERROR}
                showWithoutTouched={nrHover > 1}
              />
            </div>
            <div className="formDate ncsShadow">
              <NewTabs
                containerClassName="tabInsideShadowBox"
                title="Loc consum"
                subTitle={`Locul de consum este același cu ${
                  formData.isCasnic ? "domiciliul" : "sediul social al firmei"
                }?`}
                items={locConsumTabItems}
                idTabSelected={formData.locConsum.isSame ? 1 : 2}
                setIdTabSelected={(id: number) =>
                  setFormData(oldData => ({
                    ...oldData,
                    locConsum: { ...oldData.locConsum, isSame: id === 1 },
                  }))
                }
              />
              {!formData.locConsum.isSame && (
                <div className="formDateInsideShadowBox">
                  <FormInput
                    type="text"
                    key="LCA"
                    containerClassName="ncsInputContainer"
                    label="Adresă loc consum"
                    name="adresa"
                    onChange={handleLocConsumDataChange}
                    value={formData.locConsum.adresa}
                    errorTest={ALFA_NUMERIC_REGEX}
                    errorMessage={ALFA_NUMERIC_ERROR}
                    showWithoutTouched={nrHover > 1}
                  />
                  <FormInput
                    type="text"
                    key="LCL"
                    containerClassName="ncsInputContainer"
                    // containerStyle={cereriStyles.inputContainer}
                    // style={cereriStyles.input}
                    // errorStyle={cereriStyles.inputError}
                    label="Localitate"
                    name="localitate"
                    onChange={handleLocConsumDataChange}
                    value={formData.locConsum.localitate}
                    errorTest={ALFA_NUMERIC_REGEX}
                    errorMessage={ALFA_NUMERIC_ERROR}
                    showWithoutTouched={nrHover > 1}
                  />
                  <FormInput
                    type="text"
                    key="LCJ"
                    containerClassName="ncsInputContainer"
                    // containerStyle={cereriStyles.inputContainer}
                    // style={cereriStyles.input}
                    // errorStyle={cereriStyles.inputError}
                    label="Județ"
                    name="judet"
                    onChange={handleLocConsumDataChange}
                    value={formData.locConsum.judet}
                    errorTest={ALFA_NUMERIC_REGEX}
                    errorMessage={ALFA_NUMERIC_ERROR}
                    showWithoutTouched={nrHover > 1}
                  />
                </div>
              )}
            </div>

            <div className="formDate ncsShadow">
              <FormInput
                type="text"
                key="Motiv"
                containerClassName="ncsInputContainer"
                // containerStyle={cereriStyles.inputContainer}
                // style={cereriStyles.input}
                // errorStyle={cereriStyles.inputError}
                labelTitle="Solicit eliberarea unui aviz pentru execuția lucrărilor de: "
                label="Completați în acest câmp tipul de lucrare (canalizare, curent, etc.)"
                name="motivEliberare"
                onChange={handleFormDataChange}
                value={formData.motivEliberare}
                errorTest={ALFA_NUMERIC_REGEX}
                errorMessage={ALFA_NUMERIC_ERROR}
                showWithoutTouched={nrHover > 1}
              />
              <FormInput
                type="text"
                key="L"
                containerClassName="ncsInputContainer"
                // containerStyle={cereriStyles.inputContainer}
                // style={cereriStyles.input}
                // errorStyle={cereriStyles.inputError}
                labelTitle="Lungimea estimată a conductei de gaz (metri)"
                name="lungimeConducta"
                onChange={handleFormDataChange}
                value={formData.lungimeConducta?.toString() ?? ""}
                errorTest={AMOUNT_REGEX}
                errorMessage={AMOUNT_ERROR}
                showWithoutTouched={nrHover > 1}
              />
            </div>
            <NewCheckBox
              id="contactCheck"
              checked={formData.agreedToContact}
              labelText="Sunt de acord să fiu contactat în vederea eventualelor corecții de plată și/sau completări ale documentelor"
              onChange={() => {
                setFormData(oldData => ({
                  ...oldData,
                  agreedToContact: !oldData.agreedToContact,
                }))
              }}
              showError={nrHover > 1 && !formData.agreedToContact}
            />
            <NewCheckBox
              id="sendCheck"
              checked={formData.agreedToSend}
              labelText="Doresc să trimit cererea pentru obținerea avizului de amplasament la verificare"
              onChange={() => {
                setFormData(oldData => ({
                  ...oldData,
                  agreedToSend: !oldData.agreedToSend,
                }))
              }}
              showError={nrHover > 1 && !formData.agreedToSend}
            />
            <CheckBoxAgreeToTerms
              id="policyCheck"
              checked={formData.agreedToTerms}
              onChange={() =>
                setFormData(oldData => ({
                  ...oldData,
                  agreedToTerms: !oldData.agreedToTerms,
                }))
              }
              showError={nrHover > 1 && !formData.agreedToTerms}
            />
          </div>
        )}

        <FormeErrorLine
          status="error"
          errorMessage={getFormErrorMessageForStep(step)}
          doesFormHaveErrors={!isButtonEnabled(step, formData, files)}
          nrHover={nrHover}
          setNrHover={setNrHover}
        />
        {infoMsg.status !== "ok" && (
          <Button
            isLoading={infoMsg.isLoading}
            as={step < 2 ? "Link" : "button"}
            to={step < 2 ? `../${step + 1}` : undefined}
            disabled={!isButtonEnabled(step, formData, files)}
            aspect="primary"
            className="ncsButton"
            label={cereriButtonText[step - 1]}
            onClick={step < 2 ? undefined : handleSubmit}
          />
        )}
      </form>
      <PopupLayout
        querySelectorForBlurredBackground="#formAvizAmplasament"
        querySelectorForStopScrolling="#cerereAvizAmplasamentPage"
        titleExtraClassName="popupExtraTitle"
        popupState={{
          showPopup:
            step === 2 && (infoMsg.isLoading || infoMsg.status != null),
          type: "infoCerere",
          title:
            infoMsg.isLoading && infoMsg.status == null
              ? "Se trimite cererea"
              : infoMsg.status === "ok"
              ? "Cererea a fost trimisă cu succes"
              : "A apărut o eroare la transmiterea cererii",
          infoMsg: infoMsg,
        }}
      />
      <Footer />
    </div>
  )
}

const testFormData = {
  isCasnic: true,
  denumire: "Sârbu Alexandru",
  cuicnp: "1940527225898",
  nrInreg: "",
  adresa: "Str. Gându, nr.6",
  localitate: "Iași",
  judet: "Iași",
  email: "sarbu.alex27@gmail.com",
  telefon: "0753500775",
  locConsum: {
    isSame: true,
    // adresa: "Str. Clopotari, nr.28",
    // localitate: "Iași",
    // judet: "Iași",
  },
  reprezentantLegal: {
    isReprezentant: false,
    // nume: "Marcoci-Gînju Cosmin",
  },
  modalitateRaspuns: 1,
  agreedToTerms: false,
  nume: "g",
}

const testDataPJ = {
  isCasnic: false,
  denumire: "S.C. AZZA DDC S.R.L.",
  cuicnp: "34728271",
  nrInreg: "J22/1181/2015",
  adresa: "Str. Gandu, nr. 6",
  localitate: "Aron",
  judet: "Iasi",
  email: "sarbu.alex27@gmail.com",
  telefon: "0710100100",
  locConsum: {
    isSame: false,
    adresa: "Str. Clopotari",
    localitate: "Aroneanu",
    judet: "Iasi",
  },
  reprezentantLegal: {
    isReprezentant: false,
    nume: "",
  },
  modalitateRaspuns: 1,
  agreedToTerms: true,
  numeReprezentant: "Sarbu Alexandru",
}
